<template>
  <y_layout>
    <div class="flex-def flex-zCenter flex-cCenter" style="height: 100%">
      <div class="box-card flex-def">
        <div class="form-box flex-def flex-zCenter flex-cStart">
          <div class="form-box-item flex-def flex-zCenter flex-cCenter flex-zTopBottom">
            <div style="font-weight: 600;font-size: 1.6rem;padding: 2rem 0;margin-top: 1rem">重置密码</div>
            <el-form label-position="top" :model="ruleForm" :rules="rules" ref="ruleForm" class="ruleForm">
              <el-form-item label="手机号码" prop="account">
                <el-input v-model="ruleForm.account" placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item prop="code">
                <el-row :gutter="20">
                  <el-col :span="15"><el-input v-model="ruleForm.code" placeholder="请输入验证码"></el-input></el-col>
                  <el-col :span="9"><el-button @click="$message('仅演示,点击完成返回登陆页面')" type="primary">获取验证码</el-button></el-col>
                </el-row>
              </el-form-item>
              <el-form-item label="密码" prop="password">
                <el-input v-model="ruleForm.password" placeholder="请输入您的登陆密码"></el-input>
              </el-form-item>
              <el-form-item prop="re_password">
                <el-input v-model="ruleForm.re_password" placeholder="请再次输入您的登陆密码"></el-input>
              </el-form-item>
            </el-form>
            <div style="width: 100%;margin-top: 1rem">
              <el-button @click="$router.push('/')" type="primary" style="width: 100%">完成</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </y_layout>
</template>

<script>
import y_layout from "@/components/sys/y_layout";
export default {
  components:{y_layout},
  name: "forget",
  data(){
    return{
      remember:false,
      ruleForm:{
        account:"",
        code:"",
        password: "",
        re_password:"",
      },
      rules:{
        account: [
          { required: true, message: '请输入登陆账号', trigger: 'blur' },
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ],
        password:[
          { required: true, message: '请输入登陆密码', trigger: 'blur' },
        ],
        re_password:[
          { required: true, message: '请再次输入登陆密码', trigger: 'blur' },
        ]
      }
    }
  },
}
</script>

<style>
@import "./sys.css";
</style>