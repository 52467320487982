<template>
  <div class="flex-def flex-zCenter flex-cCenter flex-zTopBottom"
       style="height: 3rem;font-size: 12px;color: #b8b9bd;background-color: #f8f8f8">
    <div>{{ $store.state.setting.copyright }}</div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "y_layout_foot",
  data(){
    return{}
  },
  mounted() {
  },
  methods:{
  }
}
</script>

<style scoped>

</style>